import { handleActions } from 'redux-actions'

const initialState = {
  products: {
    list: [],
    paging: {},
    requesting: false,
    error: null
  },
  productDiscounts: {
    list: [],
    paging: {},
    requesting: false,
    error: null
  },
  productShopify: {
    list: [],
    paging: {},
    error: null
  },
  productItemShopify: {
    list: [],
    paging: {},
    error: null
  },
  productImages: {
    list: []
  },
  productItems: {
    list: []
  },
  productDiscount: {},
  productDetail: {},
  productDiscountProducts: {
    list: []
  },
  productDiscountSummary: {},
  brands: {
    list: [],
    paging: {}
  },
  vendors: {
    list: [],
    paging: {}
  },
  category: {
    list: [],
    paging: {},
    error: {}
  },
  productImagesShopify: {
    list: [],
    error: {}
  },
  colors: {
    options: [],
    list: [],
    error: {}
  },
  isLoading: {}
}

const setProducts = (state, payload) => ({
  ...state,
  products: {
    ...state.products,
    ...payload
  }
})

const setProductDiscounts = (state, payload) => ({
  ...state,
  productDiscounts: {
    ...state.productDiscounts,
    ...payload
  }
})

const setLoading = (state, payload, isLoading = true) => ({
  ...state,
  isLoading: {
    ...state.isLoading,
    [payload]: isLoading
  }
})

const reducer = handleActions(
  {
    PRODUCTS_LOAD_START: (state, action) => setLoading(state, action.payload, true),
    PRODUCTS_LOAD_END: (state, action) => setLoading(state, action.payload, false),
    // products
    GET_PRODUCTS_REQUEST: (state) => setProducts(state, {
      requesting: true
    }),
    GET_PRODUCTS_SUCCESS: (state, action) => setProducts(state, {
      list: action.payload.list,
      paging: action.payload.paging,
      requesting: false
    }),
    GET_CATEGORY_FAILED: (state, action) => ({
      ...state,
      category: {
        error: action.payload.errors ? action.payload.errors.map((x) => x.message).join(', ') : action.payload
      }
    }),
    GET_CATEGORY_SUCCESS: (state, action) => ({
      ...state,
      category: {
        list: action.payload.list,
        paging: action.payload.paging
      }
    }),
    GET_PRODUCTS_FAILED: (state, action) => setProducts(state, {
      requesting: false,
      error: action.payload.errors ? action.payload.errors.map((x) => x.message).join(', ') : action.payload
    }),
    // product discounts
    GET_PRODUCT_DISCOUNTS_REQUEST: (state) => setProductDiscounts(state, {
      requesting: true
    }),
    GET_PRODUCT_DISCOUNTS_SUCCESS: (state, action) => setProductDiscounts(state, {
      list: action.payload.list,
      paging: action.payload.paging,
      requesting: false
    }),
    GET_PRODUCT_DISCOUNTS_FAILED: (state, action) => setProductDiscounts(state, {
      requesting: false,
      error: action.payload.errors ? action.payload.errors.map((x) => x.message).join(', ') : action.payload
    }),
    GET_ALL_SHOPIFY_PRODUCTS_SUCCESS: (state, action) => ({
      ...state,
      productShopify: {
        list: action.payload.list,
        paging: action.payload.paging
      }
    }),
    GET_ALL_SHOPIFY_PRODUCTS_FAILED: (state, action) => ({
      ...state,
      productShopify: {
        error: action.payload.errors ? action.payload.errors.map((x) => x.message).join(', ') : action.payload
      }
    }),
    GET_ALL_SHOPIFY_PRODUCT_ITEMS_SUCCESS: (state, action) => ({
      ...state,
      productItemShopify: {
        list: action.payload.list,
        paging: action.payload.paging
      }
    }),
    GET_ALL_SHOPIFY_PRODUCT_ITEMS_FAILED: (state, action) => ({
      ...state,
      productItemShopify: {
        error: action.payload.errors ? action.payload.errors.map((x) => x.message).join(', ') : action.payload
      }
    }),
    // product discount single
    GET_PRODUCT_DISCOUNT_SUCCESS: (state, action) => ({
      ...state,
      productDiscount: action.payload
    }),
    // product discount products
    GET_PRODUCT_DISCOUNT_PRODUCTS_SUCCESS: (state, action) => ({
      ...state,
      productDiscountProducts: {
        list: action.payload.list
      }
    }),
    // product discount summary
    GET_PRODUCT_DISCOUNT_SUMMARY_SUCCESS: (state, action) => ({
      ...state,
      productDiscountSummary: action.payload
    }),
    // brands
    GET_BRANDS_SUCCESS: (state, action) => ({
      ...state,
      brands: {
        list: action.payload.list,
        paging: action.payload.paging
      }
    }),
    // brands
    GET_VENDORS_SUCCESS: (state, action) => ({
      ...state,
      vendors: {
        list: action.payload.list,
        paging: action.payload.paging
      }
    }),
    GET_PRODUCT_DETAIL_BY_ID_SUCCESS: (state, action) => ({
      ...state,
      productDetail: action.payload
    }),
    GET_ALL_IMAGES_BY_PRODUCT_ID_SUCCESS: (state, action) => ({
      ...state,
      productImagesShopify: {
        list: action.payload.list
      }
    }),
    GET_ALL_IMAGES_BY_PRODUCT_ID_FAILED: (state, action) => ({
      ...state,
      productImagesShopify: {
        error: action.payload.errors ? action.payload.errors.map((x) => x.message).join(', ') : action.payload
      }
    }),
    GET_PRODUCT_COLORS_SUCCESS: (state, action) => ({
      ...state,
      colors: {
        list: action.payload.list
      }
    }),
    GET_PRODUCT_COLORS_FAILED: (state, action) => ({
      ...state,
      colors: {
        error: action.payload.errors ? action.payload.errors.map((x) => x.message).join(', ') : action.payload
      }
    }),
    GET_PRODUCT_COLORS_OPTIONS_SUCCESS: (state, action) => ({
      ...state,
      colors: {
        options: action.payload.list
      }
    }),
    GET_PRODUCT_COLORS_OPTIONS_FAILED: (state, action) => ({
      ...state,
      colors: {
        error: action.payload.errors ? action.payload.errors.map((x) => x.message).join(', ') : action.payload
      }
    }),
    GET_ITEMS_BY_PRODUCT_ID_SUCCESS: (state, action) => ({
      ...state,
      productItems: action.payload
    }),
    GET_PRODUCT_IMAGES_BY_PRODUCT_ID_SUCCESS: (state, action) => ({
      ...state,
      productImages: {
        list: action.payload.list
      }
    })
  },
  initialState
)

export default reducer
